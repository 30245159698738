$(document).ready(->

  $('.slider').each ->
    $(this).slick
      slidesToShow: 1
      # fade: true
      dots: true
      centerMode: true
      centerPadding: 0



).on('click', 'a.nav_show', (e) ->
  e.preventDefault()
  $(this).toggleClass('is_open')
  $(this).next().toggleClass('is_open')
  return
)
